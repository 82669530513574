<template>
  <div class="footer__content">
    <div
      class="main__footer"
      :style="{
        backgroundImage: `url('./../../imgs/footer/bg_footer.jpg')`,
        position: 'relative',
      }"
    >
      <v-container class="main__footer__content">
        <v-row class="ma-0">
          <v-col cols="12" sm="12" md="6" lg="4" xl="4">
            <p class="subtitulo_footer">Parceria:</p>
            <v-row class="ma-0" style="align-items: center">
              <v-col cols="7">
                <img
                  class="img__item my-auto"
                  src="./../../public/imgs/new_logo_landscape_white.png"
                  alt="Logo tipo do Meia Entrada Colorido "
                />
              </v-col>
              <v-col cols="5">
                <img
                  class="img__item my-auto"
                  src="./../../public/imgs/logos/logo_high_abe_transparent.png"
                  alt="Logo tipo do Meia Entrada Colorido "
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="8" xl="8">
            <p>
              <a
                :href="privacyPolicy"
                class="link-politica"
                target="_blank"
              >
                Política de Privacidade / Termos de Uso
              </a>
            </p>
            <p class="subtitulo_footer">Baixe o app da Meia Entrada:</p>
            <v-row class="ma-0">
              <v-col cols="6" sm="6" md="4" lg="3" xl="3">
                <a
                  href="https://itunes.apple.com/br/app/id1670378560?l=pt&mt=8"
                  target="_blank"
                  class="link"
                >
                  <img
                    class="img__item"
                    src="./../../public/imgs/btns/btn_apple.png"
                    alt="botão da loja de aplicativos da apple "
                  />
                </a>
              </v-col>
              <v-col cols="6" sm="6" md="4" lg="3" xl="3">
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.meiaentradadigital&hl=pt_BR"
                  class="link"
                  target="_blank"
                >
                  <img
                    class="img__item"
                    src="./../../public/imgs/btns/btn_google_play.png"
                    alt="botão da loja de aplicativos da google play"
                  />
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer_copy">
        <p>Copyright © 2024 Meia Entrada. Todos os Direitos Reservados</p>

        <p>
          Desenvolvido por
          <a
            href="https://hoomweb.com"
            target="_blank"
            :style="`background-image: url(./../../imgs/hoomweb_logo_black.png)`"
          >
            <v-responsive :aspect-ratio="16 / 3"></v-responsive>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterComponent",
  props: {},
  data() {
    return {
      privacyPolicy: process.env.VUE_APP_API + '/privacy-policy',
    }
  },
  methods: {},
};
</script>

<style scoped>
.main__footer__content {
  backdrop-filter: blur(15px);
}
.main__footer__content * {
  color: white !important;
}
a.link-politica {
  text-decoration: none;
}
.footer {
  position: relative;
  z-index: 1;
  display: flex;
}
.footer_parceria {
  width: 400px;
}
.footer_app {
  width: 500px;
}
.img__item {
  width: 100%;
}
.img_logo_abe {
  width: 90px;
}
.subtitulo_footer {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.footer_copy {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0;
}
.footer_copy p {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: black;
  display: flex;
  margin: unset;
}
.footer_copy p a {
  display: block;
  background-size: contain;
  background-position: center;
  width: 150px;
}
.main__footer {
  background-size: cover;
  background-position: left center;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .main__footer {
    background-size: cover;
    background-position: center;
  }
  .main__footer__content {
    backdrop-filter: blur(50px);
  }
  .footer_fantasma {
    height: 265px;
  }
  .footer,
  .footer_copy {
    height: auto;
    /* background-color: aqua; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer_parceria,
  .footer_app {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
