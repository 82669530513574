import Vue from 'vue'
import App from './App.vue'
// import "es6-promise/auto";
import 'core-js/es/array';
import VueRouter from "vue-router";
import axios from "axios";
import Notifications from "vue-notification";
import DatetimePicker from "vuetify-datetime-picker";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/src/locale/pt.ts";
import router from "./router";
import store from "./store";
import VueSplide from '@splidejs/vue-splide';
// import '@splidejs/splide/dist/css/themes/splide-default.min.css';

// axios.defaults.baseURL = `http://localhost:3000/api`;
function buildAxios(url) {
  return axios.create({
    baseURL: url,
    headers: {
      common: {
        Accept: "application/json",
      }
    },
  })
}


Vue.prototype.$http = buildAxios(process.env.VUE_APP_PAYMENT_API + "/api");
Vue.prototype.$httpMain = buildAxios(process.env.VUE_APP_API + "/api");
Vue.router = router;
Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(DatetimePicker);
Vue.use(VueSplide);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
});

Vue.use(Vuetify);
Vue.config.productionTip = false;

Vue.prototype.$encode = (data) => {
  data = JSON.stringify(data);
  const encoder = new TextEncoder();
  let bytesArray = encoder.encode(data);
  bytesArray.forEach((i, index) => {
    if (i > 1 && index % 2 === 0) {
      i + 1;
    }
  });
  const r = bytesArray.join(",");
  return r;
}

Vue.prototype.$decode = (data) => {
  data = data.split(",");
  data.map((i) => Number.parseInt(i))
  data.forEach((i, index) => {
    if (i > 1 && index % 2 === 0) {
      i - 1;
    }
  });
  const decode = new TextDecoder();
  const r = JSON.parse(decode.decode(new Uint8Array(data)));
  return r;
}

Vue.prototype.$isRenewPartner = () => {
  return ["renovacao.meiaentradadigital.com.br"].includes(
    window.location.hostname
  );
}

new Vue({
  render: (h) => h(App),
  store,
  router,
  vuetify: new Vuetify(),
}).$mount("#app");