import Vue from "vue";
// import axios from "axios";
import store from "./store";
import Router from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("./pages/home.vue"),
    name: "Home",
  },
  {
    path: "/confirm",
    component: () => import("./pages/confirm.vue"),
    name: "Confirm",
  },
  {
    path: "/finished",
    component: () => import("./pages/finished.vue"),
    name: "Finished",
  },
  {
    path: "/pix",
    component: () => import("./pages/pix.vue"),
  },
];

const router = new Router({
  history: true,
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  (async () => {
    store.commit("setLoadingDomain", true);
    try {
      const origin = window.location.origin;
      const domain = await Vue.prototype.$http.get(
        `/partner-domain?url=${origin}`
      );
      if (domain.data) {
        store.commit("setDomain", domain.data.data);
      }
      store.commit("setLoadingDomain", false);
    } catch (e) {
      console.error(e);
      store.commit("setLoadingDomain", false);
    }
  })();
  /**
   * Para mandar alguém diretamente para  página do PIX, mas no PHP houve dificuldade em recriar as funções encode e decode de string do vuejs.
   * Então o laravel envia o email ou whatsapp avisando a falta de pagamento,  a pessoa ao clicar vêm com o parâmetro od, que parseado contém eo (email original) e io (id original)
   */
  if (to.path == "/pix" && to.query.od) {
    const decoded = atob(to.query.od);
    const { eo, io } = JSON.parse(decoded);
    const queryParams = {
      e: Vue.prototype.$encode(eo),
      i: Vue.prototype.$encode(io),
    };
    next({ path: to.path, query: queryParams });
  } else {
    next();
  }
});

export default router;
