<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <notifications position="bottom center" group="app"></notifications>
      <FooterComponent />
      <a
        href="https://wa.me/5511939081255"
        target="_blank"
        class="whatsapp"
        :style="`background-image: url(./../../imgs/whatsapp.png)`"
      ></a>
    </v-main>
  </v-app>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';
export default {
  name: 'App',
  components: { FooterComponent },

  data: () => ({}),
};
</script>

<style scoped>
.whatsapp {
  background-size: contain;
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999999999;
}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  scrollbar-width: auto;
  scrollbar-color: gray transparent;
  transition: 0.3s;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
  border: 3px solid transparent;
}

/**
    Customizações de sistema
  */
.data__table {
  border: 1px solid #8080804e;
}

.data__table .v-data-table-header {
  background-color: #8080804e;
}

.data__table .v-data-table-header > tr > th > span {
  font-weight: bolder;
  font-size: 0.9rem;
  color: black;
}

.label__item {
  font-weight: bolder;
  font-size: 1rem;
  color: black;
  font-family: 'Libre Franklin', sans-serif;
}
.label__item.invisible {
  color: transparent;
  user-select: none;
}
.label__item .required__item {
  color: red;
  font-size: 1.2rem;
}

.clickable {
  cursor: pointer;
}

#app {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
  color: black;
  box-sizing: border-box;
  position: relative;
  scroll-behavior: smooth;
  overflow-x: clip;
  /* overflow-x: hidden; */
  background-repeat: no-repeat;
  background-size: cover;
}

.splide__arrow--next {
  background-image: url('./../public/imgs/icones/next.png') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.splide__arrow--prev {
  background-image: url('./../public/imgs/icones/previous.png') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.splide__arrow--next svg,
.splide__arrow--prev svg {
  display: none;
}

.link {
  color: black;
}
.link:hover {
  color: black;
  text-decoration: none;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}

</style>
